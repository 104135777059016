import React, { useContext, useEffect, useState } from 'react';
import { Header as AntHeader } from 'antd/lib/layout/layout';
import {
  Col,
  Row,
  Space,
  Typography,
  Image,
  Radio,
  RadioChangeEvent,
} from 'antd';
import { Account } from './Account';
import { useLocation } from 'react-router-dom';
import { SpaceContext, UserDataContext } from '../contexts';
import glanceLogo from '../assets/glance-logo-red.png';
import { PRODUCT_NAME_FEED } from '../lang/en';
import { PRODUCT_NAME_SPACES } from '../lang/spaces/en';
import { isInternalPublisher } from '../utils/helper';

const { Title } = Typography;
export const Header: React.FC = (): JSX.Element => {
  const { setOnSpaces } = useContext(SpaceContext);
  // const [activeLink, setActiveLink] = useState('');
  const { pathname } = useLocation();
  const isSpaces = pathname.includes('/spaces');
  const [path, setPath] = useState(window.location.pathname);
  const { userData } = useContext(UserDataContext);
  const selectedProductPath =
    userData && isInternalPublisher(userData) ? path : '';

  const onProductChange = (e: RadioChangeEvent) => {
    window.location.href = e.target.value;
    setPath(e.target.value);
  };

  useEffect(() => {
    if (isSpaces) {
      setOnSpaces(isSpaces);
    }
  }, [isSpaces, setOnSpaces]);

  // const handleNavLinkClick = (e: React.MouseEvent<HTMLElement>): void => {
  //   const { target } = e;
  //   if (target) {
  //     setActiveLink((target as HTMLElement).id);
  //   }
  // };

  return (
    <AntHeader>
      <Row justify="space-between">
        <Col>
          <div className="header-menu">
            {/* <Link to="/"> */}
            <Title level={3} className="brand-logo" type="secondary">
              <Image height={30} src={glanceLogo} preview={false} />
            </Title>
            {/* TODO: Uncomment when adding navigation for topic mgmt */}
            {/* </Link> */}
          </div>
        </Col>
        <Col>
          <Space size="large">
            <Account />
          </Space>
        </Col>
      </Row>
      <Radio.Group
        value={selectedProductPath}
        buttonStyle="solid"
        onChange={onProductChange}
        className="product-switch-btn"
      >
        <Radio.Button value="/feed">{PRODUCT_NAME_FEED}</Radio.Button>
        {userData && isInternalPublisher(userData) && (
          <Radio.Button value="/spaces/home">
            {PRODUCT_NAME_SPACES}
          </Radio.Button>
        )}
      </Radio.Group>
    </AntHeader>
  );
};
